<!--
 * @Description: 
 * @Author: LiangYiNing
 * @Date: 2022-02-10 14:36:43
 * @LastEditTime: 2022-07-01 09:26:49
 * @LastEditors: ChenXueLin
-->
<template>
  <el-dialog
    v-dialogDrag
    :title="editType === 'ADD' ? '新增资源' : '修改资源'"
    :visible.sync="visible"
    width="800px"
    :before-close="handleClose"
    :close-on-click-modal="false"
    v-loading="loading"
    :element-loading-background="loadingBackground"
    custom-class="edit-job-dialog"
  >
    <el-form
      ref="form"
      label-width="90px"
      :model="form"
      :inline="true"
      class="edit-job-form"
      :rules="dynamicRules"
    >
      <el-row>
        <el-col :span="24">
          <el-form-item label="上级">
            <el-input v-model="parentMenu" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="资源类型">
            <el-radio-group
              v-model="moduleType"
              @change="clearValidate"
              :disabled="editType == 1"
            >
              <el-radio :label="1" :disabled="parentModuleType == 2"
                >目录</el-radio
              >
              <el-radio :label="2" :disabled="parentModuleType == 2"
                >菜单</el-radio
              >
              <el-radio :label="3" :disabled="parentModuleType == 1"
                >动作</el-radio
              >
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="24" v-if="moduleType == 1 || moduleType == 2">
          <el-form-item label="资源图标" prop="icon">
            <el-input v-model="form.icon"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="资源名称" prop="moduleName">
            <!--  :rules="rules.moduleName" -->
            <el-input v-model="form.moduleName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" v-if="moduleType == 1 || moduleType == 2">
          <el-form-item label="显示排序" prop="rowNo">
            <!--  :rules="rules.rowNo" -->
            <el-input v-model="form.rowNo"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" v-if="moduleType == 2">
          <el-form-item label="资源路径" prop="modulePath">
            <!-- :rules="moduleType == 2 ? rules.modulePath : []" -->
            <el-input v-model="form.modulePath"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" v-if="moduleType == 2">
          <el-form-item label="vue注册地址" prop="moduleComponent">
            <el-input v-model="form.moduleComponent"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24" v-if="moduleType == 3">
          <el-form-item label="动作类型" prop="operationId">
            <!-- :rules="moduleType == 3 ? rules.operationId : []" -->
            <e6-vr-select
              :data="actionTypeList"
              :is-title="true"
              v-model="form.operationId"
              clearable
              :props="{
                id: 'operationId',
                label: 'operationName'
              }"
            >
            </e6-vr-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="备注" prop="remark">
            <el-input v-model="form.remark"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" v-if="moduleType == 2">
          <el-form-item label="组件名" prop="componentName">
            <!-- :rules="moduleType == 2 ? rules.modulePath : []" -->
            <el-input v-model="form.componentName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" v-if="moduleType == 2">
          <el-form-item label="是否缓存" prop="isCache">
            <el-radio-group v-model="form.isCache">
              <el-radio :label="1">缓存</el-radio>
              <el-radio :label="0">不缓存</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="12" v-if="moduleType == 1 || moduleType == 2">
          <el-form-item label="显示状态" prop="isShow">
            <el-radio-group v-model="form.isShow">
              <el-radio :label="1">显示</el-radio>
              <el-radio :label="0">隐藏</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="资源状态" prop="isEnable">
            <el-radio-group v-model="form.isEnable">
              <el-radio :label="1">启用</el-radio>
              <el-radio :label="0">停用</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div class="dialog-footer" slot="footer">
      <el-button class="cancel" @click="handleClose">取消</el-button>
      <el-button type="primary" @click="handleSave">确定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import base from "@/mixins/base";
import { printError } from "@/utils/util";
import {
  createModule,
  updateModule,
  getModule,
  getOperationDownList
} from "@/api";
export default {
  name: "",
  mixins: [base],
  data() {
    return {
      visible: false,
      loading: false,
      editType: "ADD",
      parentMenu: "",
      parentModuleType: "",
      moduleType: 1, // 资源类型
      form: {
        moduleId: "",
        versionNumber: "",
        operationId: "", //动作类型
        actionModuleId: "", //动作跳转资源
        icon: "", //图标
        isEnable: 1, //资源状态
        isShow: 1, //显示状态
        moduleComponent: "", //vue注册地址
        moduleName: "", //资源名称
        modulePath: "", //资源路径
        componentName: "", // 组件名
        isCache: 1, // 是否缓存
        remark: "", //备注
        rowNo: "" //排序号
      },
      actionTypeList: [] // 动作类型枚举
    };
  },
  computed: {
    dynamicRules() {
      let editFormRules = {};
      if (this.moduleType == 1) {
        editFormRules = {
          moduleName: [
            {
              required: true,
              message: "请输入资源名称",
              trigger: ["blur", "change"]
            }
          ],
          rowNo: [
            {
              required: true,
              message: "请输入显示排序",
              trigger: ["blur", "change"]
            }
          ]
        };
      }
      if (this.moduleType == 2) {
        editFormRules = {
          moduleName: [
            {
              required: true,
              message: "请输入资源名称",
              trigger: ["blur", "change"]
            }
          ],
          rowNo: [
            {
              required: true,
              message: "请输入显示排序",
              trigger: ["blur", "change"]
            }
          ],
          modulePath: [
            {
              required: true,
              message: "请输入资源路径",
              trigger: ["blur", "change"]
            }
          ],
          moduleComponent: [
            {
              required: true,
              message: "请输入vue注册地址",
              trigger: ["blur", "change"]
            }
          ],
          componentName: [
            {
              required: true,
              message: "请输入组件名",
              trigger: ["blur", "change"]
            }
          ]
        };
      }
      if (this.moduleType == 3) {
        editFormRules = {
          moduleName: [
            {
              required: true,
              message: "请输入资源名称",
              trigger: ["blur", "change"]
            }
          ],
          operationId: [
            {
              required: true,
              message: "请选择动作类型",
              trigger: ["blur", "change"]
            }
          ]
        };
      }
      return editFormRules;
    }
  },
  created() {
    this.initData();
  },
  methods: {
    init(row, handleType) {
      this.visible = true;
      if (handleType === "ADD") {
        this.editType = handleType;
        this.parentMenu = row.moduleName;
        this.parentModuleType = row.moduleType;
        this.moduleType = row.moduleType === 2 ? 3 : row.moduleType;
        this.pid = row.id;
      } else {
        this.editType = handleType;
        this.parentModuleType = "";
        this.parentMenu = row.parentMenu; // 在父组件获取的
        this.moduleType = row.moduleType;
        this.pid = row.pid;
        this.displayform(row);
      }
      this.$nextTick(() => {
        this.clearValidate();
      });
    },
    async initData() {
      try {
        let res = await getOperationDownList();
        if (res.code === "OK") {
          this.actionTypeList = res.data;
        }
      } catch (error) {
        printError(error);
      }
    },
    // 回显
    async displayform(row) {
      try {
        this.loading = true;
        let res = await getModule({
          moduleId: row.id,
          moduleType: row.moduleType
        });
        if (res.code === "OK") {
          res.data.moduleId = row.id;
          row = res.data;
          for (let key in this.form) {
            this.form[key] = row[key];
            this.form.isCache = row.isCache ? 1 : 0;
          }
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    handleSave() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.createModule();
        }
      });
    },
    // 向后台请求
    async createModule() {
      try {
        let handleAPI = updateModule;
        let handlemsg = "修改成功";
        if (this.editType === "ADD") {
          handleAPI = createModule;
          handlemsg = "新增成功";
        }
        let res = await handleAPI({
          parentId: this.pid,
          moduleType: this.moduleType,
          ...this.form
        });
        if (res.code === "OK") {
          this.$message.success(handlemsg);
          this.$emit("queryList");
          this.handleClose();
        }
      } catch (error) {
        printError(error);
      }
    },
    handleClose() {
      this.visible = false;
      this.editType = "ADD";
      this.moduleType = 1;
      this.pid = "";
      this.parentMenu = "";
      this.$refs.form.resetFields();
    },
    clearValidate() {
      this.$refs["form"].clearValidate();
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/ .edit-job-dialog {
  .el-dialog__body {
    padding: 20px;
    .el-input {
      width: 280px;
    }
  }
}
</style>
